<template>
  <div class="px-4 pt-3">
    <v-card>
      <v-row class="px-4">
        <v-col cols="6" sm="4">
          <v-menu
            ref="from"
            v-model="fromDateDialog"
            :close-on-content-click="false"
            :return-value.sync="from"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFrom"
                label="С:"
                readonly
                v-bind="attrs"
                prepend-icon="mdi-calendar-blank-multiple"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="from"
              no-title
              scrollable
              dense
              locale="ru"
              :first-day-of-week="1"
            >
              <v-spacer />
              <v-btn text color="primary" @click="fromDateDialog = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  fromDateDialog = false;
                  $refs.from.save(from);
                  loadRow();
                "
              >
                Применить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:[`item.calculated_by[0].quantity`]="{ item }">
          <p>
            <span
              v-for="itemCalc in item.calculated_by"
              :key="itemCalc.price.id"
            >
              {{ itemCalc.price.name }} : {{ itemCalc.quantity }}
            </span>
          </p>
        </template>
        <template v-slot:[`item.check_in`]="{ item }">
          {{ moment.unix(item.check_in).format("DD.MM.YYYY") }}
        </template>
        <template v-slot:[`item.check_in_time`]="{ item }">
          {{ item.check_in_time }} - {{ item.check_out_time }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="warning" @click="openReturn(item)">Возврат</v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="800">
      <v-card class="pa-5">
        <v-card-title
          class="font-weight-bold text-left"
          style="font-size: 24px; padding: 0"
          >Изменение брони
        </v-card-title>
        <v-row class="py-5">
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="modelItem.return_sum"
              label="Сумма к возврату"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="px-3">
            <v-row v-for="item in modelItem.prices" :key="item.price_id">
              <v-col cols="6" class="d-flex" style="align-items:center">
                <p class="ma-0 pa-0" v-text="item.name"></p>
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  style="width: 30px; height: 30px"
                  color="primary"
                  :disabled="item.quantity == 0"
                  @click="item.quantity--"
                  :style="{ background: statusColor(item, '-') }"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
                <span v-text="item.quantity"></span>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  style="width: 30px; height: 30px"
                  color="primary"
                  :disabled="item.quantity == item.max_quantity"
                  @click="item.quantity++"
                  :style="{ background: statusColor(item, '+') }"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn @click="returnSum" color="warning" :disabled="disabledBtn"
              >Вернуть</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import toast from "../../../plugins/toast";

export default {
  data() {
    return {
      moment: moment,
      modelItem: {},
      firstWatcherTrigger: false,
      totalItems: 0,
      dialog: false,
      options: {},
      fromDateDialog: false,
      items: [],
      disabledBtn: false,
      now: new Date().toISOString().substr(0, 10),
      from: new Date(moment(new Date())).toISOString().substr(0, 10),
      loading: false,
      headers: [
        { text: "Тип категории", value: "category_type", class: "table-title" },
        { text: "Дата", value: "check_in", class: "table-title" },
        { text: "Время", value: "check_in_time", class: "table-title" },
        { text: "ФИО", value: "full_name", class: "table-title" },
        { text: "Телефон", value: "phone_number", class: "table-title" },
        {
          text: "Количество",
          value: "calculated_by[0].quantity",
          class: "table-title"
        },
        { text: "Стоимость", value: "price", class: "table-title" },
        { text: "Источник", value: "source", class: "table-title" },
        { text: "Статус", value: "status", class: "table-title" },
        { text: "Сумма возврата", value: "return_sum", class: "table-title" },
        { text: "Действия", value: "actions", class: "table-title" }
      ]
    };
  },
  computed: {
    computedFrom() {
      if (this.from) return moment(this.from).format("DD.MM.YYYY");
      return "";
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    async loadRow() {
      this.loading = true;
      let params = {
        start: new Date(this.from).getTime() / 1000,
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      try {
        let res = await BookingApiUrls.getLeisureBookings(params);
        this.totalItems = res.meta.pagination.count;
        this.items = res.results;
        if (this.items.length == 0) {
          toast.error("Нет данных на эту дату!");
        }
      } finally {
        this.loading = false;
      }
    },
    openReturn(item) {
      let prices = [];
      item.calculated_by.forEach(e => {
        prices.push({
          price_id: e.price.id,
          quantity: 0,
          name: e.price.name
        });
      });
      this.modelItem = {
        booking_id: item.id,
        sum: item.return_sum,
        return_sum: 0,
        prices: prices
      };
      this.dialog = true;
    },
    statusColor(item, type) {
      if (type == "+" && item.quantity == item.max_quantity)
        return "lightgrey !important";
      if (type == "-" && item.quantity == 0) return "lightgrey !important";
    },
    async returnSum() {
      this.disabledBtn = true;
      try {
        this.modelItem.return_sum = +this.modelItem.return_sum;
        this.modelItem.prices = this.modelItem.prices.filter(
          e => e.quantity > 0
        );
        await BookingApiUrls.createLeisureReturn(this.modelItem);
        toast.success("Успешно изменилось!");
        this.loadRow();
        this.dialog = false;
      } finally {
        this.disabledBtn = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
